//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GradientImage',
  props: {
    left: Boolean,
    imgSrc: {
      type: String,
      default: '//summitproedu.imgix.net/landing-pages/pt-phippf.jpg?auto=enhance&fit=crop,faces',
    },
  },
  computed: {
    gradientClass () {
      return this.$route.path.includes('/group-sales/setting') ? 'from-primary-140 to-primary-05' : 'from-primary-05 to-primary-140';
    },
  },
};
